/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0
}

.no-wrap {
  white-space: nowrap;
}

@import 'front/variable';
@import 'front/theme';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/grid';
@import 'front/mixins/font';

@import 'front/component/global';
@import 'front/component/heading';

@import 'front/component/header';
@import 'front/component/sidebar';
@import 'front/component/spinner';
@import 'front/component/card';
@import 'front/component/dashboard';

@import 'front/responsive';

/* MODAL */
.cdk-overlay-backdrop {
  &.cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, .32) !important;
    opacity: 1 !important;
  }
}

.cdk-drag.list-item {
  border-bottom: 1px solid #6b2394 !important;
}

.cdk-drop-list.list {
  border: 1px solid #6b2394 !important;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.text-align-center {
  text-align: center;
}

@media screen and (max-width: 1318px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding: 8px 24px !important;
    white-space: nowrap; //no break line
  }
}

.box-custom {
  display: flex;
  align-items: center;

  &-link {
    margin: 4px 4px 0;
    display: inline-block;
  }
}

.flex-row {
  flex-direction: row;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.mw-55 {
  max-width: 55%;
}

.full-width-table {
  width: 100%;
}


.wrapper {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;

  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.radios {
  display: flex;
}


.btn-login {
  cursor: pointer;
  border: 2px solid #ffffff;
  text-align: center;
  color: #ffffff;
  border-radius: 20px;
  padding: 3px 12px;
}

.btn-more {
  padding: 15px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  border-radius: 25px !important;
  background-color: #D40960;
  font-weight: 600;

  &.register {
    background: none;
    color: #ffffff;
    border: 1px solid #fff;
  }

  &.login {
    padding: 10px !important;
  }
}


.text-tertiary {
  color: #FFBB00 !important;
}

.number {
  margin-right: 20px;
  font-size: 40px;
  font-weight: bold;
}


.line-img {
  display: flex;
  align-items: center;

  img {
    height: fit-content;
    width: fit-content;
    margin-right: 10px;
  }
}

mat-dialog-container {
  background-color: #671f72 !important;
  color: #fff !important;
  border-radius: 15px !important;
}

.flex-column {
  flex-direction: column;
}

.cdk-overlay-container{
  //backdrop-filter: blur(8px);
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: #F7F7F7;
  border: none;
}

//mat-input focused color
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  border: none;
}

// mat-input error outline color
.mat-form-field-appearance-outline.input-white.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  border: none;
}

.mat-form-field-appearance-outline.input-white{

  .mat-input-element {
    caret-color: #ffffff !important;
    border: none;
  }

  .mat-form-field-invalid .mat-input-element, .mat-warn .mat-input-element {
    caret-color: #ffffff !important;
    border: none;
  }

  .mat-form-field-label {
    color: #ffffff !important;
    border: none;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: #ffffff !important;
    border: none;
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #ffffff !important;
    border: none !important;
  }
}

.mat-form-field-appearance-outline.input-white .mat-form-field-outline {
  color: #ffffff !important;
  background-color: #74307e;
  border: none;
}

//mat-input focused color
.mat-form-field-appearance-outline.input-white.mat-focused .mat-form-field-outline-thick {
  color: #ffffff !important;
  border: none;
}

// mat-input error outline color
.mat-form-field-appearance-outline.input-white.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ffffff !important;
  opacity: 0.8 !important;
  border: none;
}


